import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ButtonGroup from "../../shared/InputElements/ButtonGroup";
import {useIntl} from 'react-intl';

const ChooseCanTurnInClunker = ({
  id = "can-turn-in-clunker",
  incentives,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "incentives.planClunker", defaultMessage: "Are you planning to turn in a clunker?"})

  return (
    <ButtonGroup
      id={id}
      value={userPrefs.get("canTurnInClunker").toString()}
      label={label}
      optionNames={[intl.formatMessage({ id: "yes", defaultMessage: "Yes"}), intl.formatMessage({ id: "no", defaultMessage: "No"})]}
      optionValues={["true", "false"]}
      handler={(e, selected) =>
        userPrefs.set({ canTurnInClunker: selected === "true" })
      }
      {...rest}
    />
  );
};

export default ChooseCanTurnInClunker;

ChooseCanTurnInClunker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  incentives: PropTypes.array
};
