import React from "react";
import PropTypes from "prop-types";
import LinkCard from   "../../LinkCard/LinkCard";
import {FormattedMessage} from 'react-intl';


const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
    <section>
      <div className="homepage-jumbotron">
        <div className="big-promise" style={{ position: "relative" }}>
          <div className="backdrop" />
          <div
            style={{
              backgroundImage: "url(" + homePageBannerImage + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              WebkitBackgroundSize: "cover",
              MozBackgroundSize: "cover",
              OBackgroundSize: "cover",
              backgroundSize: "cover",
              padding: 0,
              textAlign: "center",
              height: "475px",
              boxSizing: "border-box"
            }}>
          <h1>
            <FormattedMessage
              id="homepage.welcomeSub"
              defaultMessage="Find your EV Match!"
              description="Find your EV Match!"
            />
          </h1>
          </div>
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="LOCATE_CHARGING_STATIONS" />
              {/* <LinkCard type="EV_FACTS" /> */}
              {/* <LinkCard type="FIND_DEALERS" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}