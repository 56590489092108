


export const CA_SALES_TAX_INFO = {
        "Alberta": { salesTax: "0.0500", insurance: "1251"},
        "British Columbia": { salesTax: "0.1200", insurance: "1480"},
        "Manitoba": { salesTax: "0.1200", insurance: "1080" },
        "New Brunswick": {salesTax: "0.1500", insurance: "819"},
        "Newfoundland and Labrador": { salesTax: "0.1500", insurance: "1132"},
        "Northwest Territories": { salesTax: "0.0500", insurance: "978"},
        "Nova Scotia":{salesTax: "0.0500", insurance: "842"},
        "Nunavut": { salesTax: "0.0500", insurance: "963"},
        "Ontario": { salesTax: "0.1300", insurance: "1445"},
        "Prince Edward Island" : {salesTax: "0.1500", insurance: "796"},
        "Quebec":{salesTax: "0.14975", insurance: "661"},
        "Saskatchewan": { salesTax: "0.1100", insurance: "946"},
        "Yukon Territories": {salesTax: "0.0500", insurance: "812" },
}

