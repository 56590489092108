import React, {useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import MonthlyFuelCostChart from "../../../components/CostsCharts/MonthlyFuelCostChart";
import FuelRangeChart from "../../../components/CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../CostsCharts/CostToOwnComparison";
import CostOfOwnershipOptions from "../../../components/CostOfOwnershipOptions/CostOfOwnershipOptions";
import BreakevenChart from "../CostsCharts/BreakevenChart";

import { FormatCarName } from "../../../utils/Helpers/Format";
// import calcTotalCostOfOwnership from "./../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
// import ShowHideButton from "../ShowHideButton/ShowHideButton";
// import CalcPanels from "./CalcPanels/CalcPanels"
import RenderAssumption from "../../../components/RenderAssumption/RenderAssumption";

import {
  SALES_TAX,
  DOWN_PAYMENT,
} from  "../../data/assumptions/ASSUMPTIONS"
import { FormattedMessage, useIntl } from 'react-intl';

const CostOfOwnership = ({ cars, title, comparison, usedEv }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState();
  const [maintenanceData, setMaintenanceData] = useState(null);

  useEffect(() => {
    const loadInsuranceData = async (vehicle_handle) => {
      const params = {
        postcode: userPrefs.get("zipcode"),
      };
      let url = new URL(
        `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/insurance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
          },
        });
        if (response.status === 200) {
          const insuranceData = await response.json();
          setInsuranceData(insuranceData.yearly_insurances);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    const loadMaintenanceData = async (vehicle_handle) => {
       const params= {
         annualmiles: userPrefs.get('milesDrivenAnnually'),
         postcode: userPrefs.get('zipcode')
       }
       let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/maintenance`);
       let searchParams = new URLSearchParams(params);
       url.search = searchParams;
       try {
         const response = await fetch(url, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             Accept: "application/json",
             Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
           },
         });
         if (response.status === 200) {
           const maintenanceData = await response.json();
           setMaintenanceData(maintenanceData.maintenance.costs);
           setIsLoading(false);
         }
       }
       catch(error) {
         console.log("Failed to load maintenance data.");
         console.log(error);
         setIsLoading(false);
       }
     };

    if (cars && cars.length > 0) {
      loadInsuranceData(cars[0].handle);
      loadMaintenanceData(cars[0].handle);
    }
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  const car = cars[0]
  const equivalentGasVehicle = cars[1];

  let electricityCosts = userPrefs.get("electricityCosts");
  let ELECTRICITY_RATE_IN_DOLLARS_PER_KWH = {
    title: intl.formatMessage({ id: "assumption.electricityRate", defaultMessage: "Electricity Rate"}),
    link: null,
    value: electricityCosts,
    valueAsFormatted: "$" + electricityCosts + "/kWh"
  };

  // Grabbing from prefs
  let salesTax = userPrefs.get("salesTax");
  SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax"})
  SALES_TAX.value = salesTax.value;
  SALES_TAX.valueAsFormatted = salesTax.valueAsFormatted;

  DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment"})
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({ id: "assumption.downPaymentValue", defaultMessage: "10% of MSRP + Tax"})


  return (
    <section id="CostOfOwnership">
      <div className="container">
        <div className="CostOfOwnership">
          <div className="row">
            <div className={`col-sm-12 ${comparison ? `` : `text-center`}`}>
              <p className={usedEv ? `h1 p-3` : `h1`}>
                {title || (
                  <FormattedMessage
                    id="ev.compareGasVehicle"
                    defaultMessage="Compare the {car} to a similar gas vehicle, the {gasCar}"
                    description="Description of Colton Recharged"
                    values={{
                      car: FormatCarName(car),
                      gasCar: FormatCarName(equivalentGasVehicle),
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
            <CostOfOwnershipOptions cars={cars} usedEv={usedEv} />
            <br />
            <div className="input-well d-none d-md-block assumptions-area">
              <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
                <FormattedMessage
                  id="graph.assumptions"
                  defaultMessage="Assumptions"
                  description="Assumptions"
                />
              </p>

              <RenderAssumption assumption={SALES_TAX} />
              {userPrefs.get("purchaseMethod") === "cash" || usedEv ? null : (
                <RenderAssumption assumption={DOWN_PAYMENT} />
              )}
              <RenderAssumption
                assumption={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH}
              />
              <p className="legal-disclaimer">
                <small>
                  <FormattedMessage
                    id="ev.electricityRateCalc"
                    defaultMessage="Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}"
                    description="Compare Vehicle Text"
                    values={{
                      website: (
                        <a
                          href="https://www.ci.colton.ca.us/957/Electric-Utility-Rate"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here.
                        </a>
                      ),
                    }}
                  />
                </small>
              </p>
            </div>
          </div>
          <div
            id="costs-chart-areas"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>
              Results have been updated as of {new Date().toLocaleString()}.
            </span>
          </div>
          <div className="col-md-8 col-lg-9">
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED &&
            !isLoading &&
            insuranceData ? (
              <MonthlyFuelCostChart
                cars={cars}
                comparison={comparison}
                insuranceData={insuranceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
            !isLoading &&
            insuranceData ? (
              <CostToOwnComparison
                cars={cars}
                hasExtraPadding
                comparison={comparison}
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED ? (
              <FuelRangeChart car={car} />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH &&
            !isLoading &&
            insuranceData ? (
              <BreakevenChart cars={cars} insuranceData={insuranceData} />
            ) : null}
            {/* {!comparison && 
            <p className="text-left">
              <FormattedMessage 
                id="ev.carDetails.compareText"
                defaultMessage="We selected the {gasVehicle} based on its
                proximity to the {vehicle} based on brand, size, and price."
                description="Compare Vehicle Text"
                values= {{
                  gasVehicle: FormatCarName(equivalentGasVehicle),
                  vehicle: FormatCarName(car),
                }}
              />
            </p>
          } */}
            {/* <div className="row">
            <div className="col-sm-12 text-center">
              <br />
              <ShowHideButton buttonText=" Sources" displayAlertOnlyOnMobile>
                <div className="input-well">
                  <CalcPanels
                    car={car}
                    carCosts={carCosts}
                    equivalentGasVehicleCosts={comparisonVehicleCosts}
                    insuranceData={insuranceData}
                  />
                </div>
              </ShowHideButton>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object
};
