import USER_PREF_PRESETS from "./USER_PREF_PRESETS";

const ReturnAs = {
  Int: function(val) {
    return isNaN(parseInt(val, 10)) ? 0 : parseInt(val, 10);
  },
  Float: function(val) {
    return isNaN(parseFloat(val)) ? 0 : parseFloat(val);
  },
  String: val => {
    return typeof val === "string" ? val : val.toString();
  }
};

const getUserPref = (key, userPrefs) => {
  if (!userPrefs) return USER_PREF_PRESETS[key];

  const selected = userPrefs[key];
  const preset = USER_PREF_PRESETS[key];
  let type = typeof USER_PREF_PRESETS[key];

  let val;

  if (selected != null) {
    val = selected;
  } else {
    if (preset === null) {
      console.log("Alarm: Neither state nor preset is defined for " + key);
      return null;
    }
    val = preset;
  }

  if (type === "undefined") {
    console.log("Alarm: Type of " + key + " is undefined");
    return null;
  }

  if (key === "electricityCosts") {
    return ReturnAs.Float(val)
  }

  if (type === "number") {
    return ReturnAs.Int(val);
  }
  if (type === "string") {
    return ReturnAs.String(val);
  }

  return val;
};

export default getUserPref;
