import React from "react";
import PropTypes from "prop-types";
import SelectedChargingStationCard from "../../../components/LocationMap/SelectedChargingStationCard/SelectedChargingStationCard";
import ChargingStationsMapLegend from "../../../components/LocationMap/ChargingStationsMapLegend/ChargingStationsMapLegend";
import {FormattedMessage} from 'react-intl';


import "./MapControlPanel.scss";

const MapControlPanel = ({ chargingStations, children, selectedStation }) => {
  return (
    <div className="MapControlPanel">
      <div className="map-controls">{children}</div>
      {(chargingStations || []).length > 0 && (
        <>
          <SelectedChargingStationCard station={selectedStation} />
          <ChargingStationsMapLegend chargingStations={chargingStations} />
          <div className="legend ChargingStationsMapLegend">
            <div className="charging-map-disclaimer">
              <span style={{fontSize: "12px"}}>
                <FormattedMessage 
                    id="chargingMap.disclaimer"
                    defaultMessage="This map shows Level 2 (blue) and Level 3 (green) public charging stations across Canada. For more information on the different levels, or speeds, of charging, visit: {website}"
                    description="Compare Vehicle Text"
                    values= {{
                      website: 
                      <a
                        href="https://plugndrive.ca/public-charging"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        plugndrive.ca/public-charging.
                      </a>
                    }}
                  />

              </span>
            </div>
          </div>

        </>
      )}
    </div>
  );
};

MapControlPanel.propTypes = {
  chargingStations: PropTypes.array,
  children: PropTypes.node,
  selectedStation: PropTypes.object
};

MapControlPanel.defaultProps = {
  chargingStations: []
};

export default MapControlPanel;
