import React, {useState, useContext } from "react";
import {ValidatePostCode} from "../../../../utils/Helpers/CAPostCodeValidator";
// import {ValidateFiveDigitCode} from "../../../../utils/Helpers/USPostalCodeValidator";

import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

// import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.png";

import { FormattedMessage } from 'react-intl';

const InputZipcodeForChargingMap = () => {
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode,setWorkingZipcode] = useState(
    userPrefs.get("zipcode")
  );

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;


  const id = "input-zipcode-for-charging-map";
  const idAria = id + " help";

  const ValidateZipCode = (zipCode) => {
    if(ValidatePostCode(workingZipcode)) {
      return true;
    } else {
      return false;
    }
  }


  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return

    if(ValidateZipCode(workingZipcode)) {
      if(!userPrefs.zipcodeIsUpdating) {
        async function asyncCall() {
          await userPrefs.set({ workingZipcode: workingZipcode });
          if (!userPrefs.zipcodeIsUpdating) {
            userPrefs.syncWorkingZipcode();
          }
        }
        asyncCall();
      }
    }
  }

  return (
    <>
      <div>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="zipcode"
              defaultMessage="Zipcode"
              description="Zipcode"
            />
          </label>
          
          <div className="input-group mb-2 mr-sm-2">
            {/* <div className="input-group-prepend">
              <div className="input-group-text">
                <img src={IconZip} alt="" style={{ width: 15 }} />
              </div>
            </div> */}
            <input
              id={id}
              className={isInvalid ? "form-control is-invalid" : "form-control"}
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={e => setWorkingZipcode (e.target.value)}
              onKeyDown={e => handleUpdateButtonPress(e, "input")}
            />
              <button
            type="button"
            className="btn-ae btn-default mb-2 btn-block"
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
            <FormattedMessage
              id="chargingMap.updateZipcode"
              defaultMessage="Update Zipcode"
              description="Zipcode"
            />
          </button>
          </div>
        </div>
      </div>
      {(isInvalid || !ValidateZipCode( workingZipcode)) && (
        <div className="text-danger small">
          <FormattedMessage
            id="chargingMap.errorZipcode"
            defaultMessage="Error: {workingZipcode} is not a valid postcode"
            description="Error Zipcode"
            values={{
              workingZipcode: workingZipcode
            }}
          />
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
