import React from "react" 
import "./SidebarScheduleTestDrive.scss"
import { FormattedMessage } from 'react-intl';


const SidebarScheduleTestDrive = () => {

    return (
        <div className="sidebar-schedule-test-drive">
            <button type="button" className="test-drive-button">
                <a
                    href="https://www.plugndrive.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage
                        id="scheduleTestDrive"
                        defaultMessage="Schedule a Test Drive"
                        description="Schedule a Test Drive"
                    />

                </a>
            </button>
        </div>
    )
}

export default SidebarScheduleTestDrive