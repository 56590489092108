import React from "react";
import PropTypes from "prop-types";

import "./SelectedChargingStationCard.scss";

const SelectedChargingStationCard = ({ station }) => {
  if (!station) return null;

  const {
    station_name: stationName,
    street_address: streetAddress,
    city,
    state,
    zip,
    station_phone: stationPhone,
    ev_network: evNetwork,
    ev_network_web: evNetworkWeb
  } = station;

  return (
    <div className="SelectedChargingStationCard">
      <b>{stationName}</b>
      <br />
      {streetAddress}
      <br />
      {`${city}, ${state} ${zip}`}
      <br />
      {stationPhone}
      <br />
      {evNetwork &&
        evNetworkWeb && (
          <a href={evNetworkWeb} target="_blank" rel="noopener noreferrer">
            {evNetwork}
          </a>
        )}
      {(!evNetwork || !evNetworkWeb) && <em>Link to Network Unavailable</em>}
    </div>
  );
};

export default SelectedChargingStationCard;

SelectedChargingStationCard.propTypes = {
  station: PropTypes.object
};
