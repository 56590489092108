import React from "react";
import PropTypes from "prop-types";
import "./ButtonGroup.scss";

const ButtonGroup = props => {
  const { id, value, handler, optionNames, optionValues, label } = props;

  return (
    <div className="form-group ButtonGroup" id={id}>
      <span className="label-style">{label}</span>
      <div className="d-flex">
        {optionValues.map((option, i) => {
          return (
            <button
              key={i}
              className={`btn ${value === option ? "active" : ""}`}
              style={{
                width: (95 / optionValues.length).toString() + "%",
                margin: "1%"
              }}
              onClick={e => handler(e, option)}
            >
              {optionNames[i]}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  label: PropTypes.string
};
