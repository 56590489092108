import React from "react";
import PropTypes from "prop-types";
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../hooks/useMapTabs';
import { FormattedMessage } from 'react-intl';


const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode }) => {

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <section id="charging-map-section">
      <div className="container">
        <h1 className="">
          <FormattedMessage
            id="chargingStation.title"
            defaultMessage="Zipcode"
            description="Zipcode"
          />
        </h1>

        <TabbedMaps
          toggle={toggleWithHistory}
          activeTabId={activeTabId}
          userLocation={userLocation}
          dealerLocations={dealerLocations}
          zipcode={zipcode}
        />
      </div>
    </section>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
