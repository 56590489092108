export default {
    "electricVehicles": "Electric Vehicles",
    "usedElectricVehicles" : "Used Electric Vehicles",
    "compareVehicles": "Compare Vehicles",
    "incentives" : "Incentives",
    "chargingStations" : "Charging Stations",
    "homeChargers" : "Home Chargers",
    "dealers" : "Dealers",
    "newVehicles" : "New Vehicles",
    "electricians" : "Electricians",
    "home" : "Home",
    "browseElectricVehicles" : "Browse Electric Vehicles",
    "chargingStation.title" : "Public Charging Stations",
    "scheduleTestDrive":"Schedule a Test Drive",

    "electricity" : "Electricity",
    "gasoline" : "Gasoline",
    "total" : "Total",
    "description" : "Description",
    "detailedCalculations" : "Detailed Calculations",
    "charging" : "Charging",
    "emissions" : "Emissions",
    "source" : "Source",
    "vehicles" : "Vehicles",
    "make" : "Make",
    "model" : "Model",
    "all" : "All",
    "age" : "Age",
    "zipcode" : "Postcode",

    "personalizeIncentives" : "Personalize Incentives",

    "vehicle.miles" : "Kilometers",
    "vehicle.kilometers" : "km",
    "vehicle.trees" : "trees",
    "vehicle.view" : "View",
    "vehicle.more" : "more",
    "vehicle.fewer" : "fewer",
    "vehicle.greater" : "greater",

    "vehicle.plugInHybrid": "Plug in Hybrid",
    "vehicle.allElectric": "All Electric",
    "vehicle.gas": "Gasoline",
    "vehicle.afterIncentives" : "AFTER INCENTIVES",
    "vehicle.msrp" : "MSRP",
    "vehicle.estimatedIncentives" : "ESTIMATED INCENTIVES",
    "vehicle.type" : "TYPE",
    "vehicle.fuelType" : "FUEL TYPE",
    "vehicle.batterySize" : "BATTERY SIZE",
    "vehicle.electricRange" : "ELECTRIC RANGE",
    "vehicle.timeToChargeLvlTwo" : "TIME TO CHARGE - LEVEL 2",
    "vehicle.milesPerThirtyFastCharge" : "KILOMETERS PER 30 MIN OF FAST CHARGING",
    "vehicle.kilometersPerThirtyFastCharge" : "KILOMETERS PER 30 MIN OF FAST CHARGING",
    "vehicle.coTwoEmissions" : "CO2 EMISSIONS REDUCTION",
    "vehicle.phev" : "Electricity and Gasoline",
    "vehicle.bev" : "Electricity",
    "vehicle.age.one" : "0-1 years old",
    "vehicle.age.two" : "2-3 years old",
    "vehicle.age.four" : "4+ years old",

    "vehicle.type.sedan" : "Sedan",
    "vehicle.type.hatchback" : "Hatchback",
    "vehicle.type.coupe" : "Coupe",
    "vehicle.type.crossover" : "Crossover",
    "vehicle.type.minivan" : "Minivan",
    "vehicle.type.suv" : "SUV",
    "vehicle.type.wagon" : "Wagon",
    "vehicle.type.truck" : "Truck",

    "header.electricVehicles": "Electric Vehicles",
    "header.compareVehicles": "Compare Vehicles",
    "header.incentives" : "Incentives",
    "header.chargingStations" : "Charging Stations",
    "header.homeChargers" : "Home Chargers",
    
    "footer.electricVehicles" : "{electricVehicles}",
    "footer.compareVehicles": "{compareVehicles}",
    "footer.incentives" : "{incentives}",
    "footer.chargingStations" : "{chargingStations}",
    "footer.homeChargers" : "{homeChargers}",
    "footer.allRights" : "All Rights Reserved.",
    "footer.poweredBy" : "Powered by Zappyride",

    "homepage.welcome": "Colton Recharged",
    "homepage.welcomeSub" : "Find your EV Match!",

    "homepage.linkCard.browseElectricVehicles" : "Browse Electric {lineBreak} Vehicles",
    "homepage.linkCard.discoverIncentives" : "Discover {lineBreak} Incentives",
    "homepage.linkCard.locateChargingStations" : "Locate Charging {lineBreak} Stations",
    "homepage.linkCard.homeChargers" : "HOME {lineBreak} CHARGERS",

    "homepage.vehicleCarousel.title" : "We can help you find the EV that matches your lifestyle.",
    "homepage.vehicleCarousel.subTitle" : "Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car.",
    "homepage.vehicleCarousel.findEVButton" : "Find Your Electric Vehicle",

    "graph.assumptions" : "Assumptions",
    "graph.yourEV" : "Your EV Selection",
    "graph.similarGasVehicle" : "Similar Gas Vehicle",
    "graph.graphValues": "Graph Values",
    "graph.show" : "SHOW ",
    "graph.hide" : "HIDE ",
    "graph.moreExpensive" : "more expensive",
    "graph.cheaper" :"cheaper",
    "graph.toOwnOver" : "to own over ",
    "graph.years" : " Years",
    "graph.year" : " Year",

    "graph.costOfOwnership.title" : "Cost of Ownership",
    "graph.costOfOwnership.subTitle" : "The {carName} is {style} {costDeltaText} {costDeltaTextEnding}",
    "graph.costOfOwnership.subTitleThreeCars" : "The {carName} is the cheapest to own for {yearsOfOwnership} years",
    "graph.costOfOwnership.descriptionRow.vehicle" : "Vehicle",
    "graph.costOfOwnership.descriptionRow.maintenance" : "Maintenance",
    "graph.costOfOwnership.descriptionRow.insurance" : "Insurance",
    "graph.costOfOwnership.descriptionRow.electricity" : "Electricity",
    "graph.costOfOwnership.descriptionRow.gasoline" : "Gasoline",
    "graph.costOfOwnership.totalRow" : "Total",
    "graph.costOfOwnership.description" : "Description",

    "graph.costOfOwnership.chart.vehicle" : "Vehicle net Incentives, Resale",
    "graph.costOfOwnership.chart.electricity" : "Electricity",
    "graph.costOfOwnership.chart.gasoline" : "Gasoline",
    "graph.costOfOwnership.chart.maintenance" : "Maintenance",
    "graph.costOfOwnership.chart.insurance" : "Insurance",

    "graph.title.costAnalysis" : "Cost Analysis",
    "graph.title.speedOfCharge" : "Speed of Charge",
    "graph.title.electricRange" : "Electric Range",
    "graph.monthlyCostToFill.title" : "Cost to Fill Up Monthly",

    
    "graph.speedOfCharge.levelTwoChargingSpeed" : "Level 2 Charger Speed",
    "graph.speedOfCharge.fastChargingSpeed" : "Fast Charging Speed",
    "graph.speedOfCharge.mphLevelTwoCharger" : "miles per hour with a level 2 charger",
    "graph.speedOfCharge.kmLevelTwoCharger" : "kilometers per hour with a level 2 charger",
    "graph.speedOfCharge.thirtyFastCharge" : "miles per 30 minutes of fast charging",
    "graph.speedOfCharge.subTitle" : "The {car} charges {number} {costDeltaText} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerThreeCars" : "charges the most miles per hour with a level 2 charger",
    "graph.speedOfCharge.thirtyFastChargeMetric" : "kilometers per 30 minutes of fast charging",
    "graph.speedOfCharge.thirtyFastChargeThreeCarsMetric" : "charges the most kilometers per 30 min of fast charging",
    "graph.speedOfCharge.kilometersLevelTwoChargerThreeCars" : "charges the most kilometers per hour with a level 2 charger",
    "graph.speedOfCharge.thirtyFastChargeThreeCars" : "charges the most miles per 30 minutes of fast charging",
    "graph.speedOfCharge.subTitleThreeCars" : "The {car} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerTitle" : "Amount of Charge Per Hour of Level 2 Charging",
    "graph.speedOfCharge.fastChargerTitle" : "Amount of Charge Per 30 Minutes of Fast Charging",
    "graph.electricRange.subTitleThreeCars" : "The {car} has the longest Electric Range",
    "graph.electricRange.subTitleMetric":"The {car}'s electric range is {costDeltaText} by {number} kilometers",
    "graph.electricRange.subTitle" : "The {car}'s electric range is {costDeltaText} by {number} miles",
    "graph.electricRange.gasolineRange" : "Gasoline Range",
    "graph.electricRange.assumption" : "Range based on EPA Estimates",
    "graph.monthlyCostToFill.subTitle" : "The {carName} is {style} {costDeltaText} to fill up monthly",
    "graph.monthlyCostToFill.subTitleThreeCars" : "The {carName} is cheapest to fill up monthly",

    "graph.costToBreakdown" : "Cost to Breakeven",
    "graph.costToBreakdown.subTitle" : "Compare the cumulative lifetime cost of the {car1} to a {car2}",
    "graph.costToBreakdown.subTitleThreeCars" : "Compare the cumulative lifetime cost of {car1}, {car2} and {car3}",
    "graph.costToBreakdown.note" : "Note: Breakeven chart assumes the vehicles are purchased in cash.",

    "homepage.incentives.title" : "Explore potential EV incentives and tax credits",
    "homepage.incentives.subTitle" : "See how much you could save getting behind the wheel of an EV, whether you are buying or leasing. {lineBreak} Incentives are personalized for where you live.",
    "homepage.incentives.exporeIncentives" : "EXPLORE INCENTIVES",


    "compareVehicles.subTitle" : "See All Vehicles >",
    "compareVehicles.selectFuel" : "Select a Fuel Type",
    "compareVehicles.selectMake" : "Select a Make",
    "compareVehicles.selectModel" : "Select a Model",
    "compareVehicles.pickTwo" : "Pick at least 2 vehicles to start the comparison",
    "compareVehicles.disclaimer" : "Not all make / models are available.",

    "evs.welcomeSub" : "Compare electric vehicles by range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle.",
    "evs.buttonMatchScoreAndFilters" : "Match Score and Filters",
    "evs.matchScoreAndFilters" : "Refine Match Score and Filters",
    "evs.matchScoreOptions" : "Refine Match Score",
    "evs.roundTripCommute" : "Roundtrip Commute",
    "evs.budgetAfterIncentives" : "Budget after Incentives",
    "evs.minSeats" : "Minimum Seats",
    "evs.seats" : "seats",
    "evs.homeChargingAvailability" : "Home Charging Availability",
    "evs.homeChargingAvailabilityTooltip" : "More electric vehicles will be convenient for you if you can charge quickly at home.",
    "evs.noCharging" : "No Charging",
    "evs.levelOne" : "Level 1",
    "evs.levelTwo" : "Level 2",
    "evs.helpMeChoose" : "Help Me Choose",
    "evs.chargingAvailability" : "Charging Availability",
    "evs.chargercard.chargingtime.na": "NA",
    "evs.disclaimer" : "Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer.",
    
    "chargingWizard.carOvernight" : "Where will you park your car overnight?",
    "chargingWizard.parkingGarage" : "Private garage or parking spot",
    "chargingWizard.onTheStreet" : "On the street",
    "chargingWizard.publicParking" : "Public parking lot",
    "chargingWizard.electricianInstall" : "Would an electrician be allowed to install a charging station?",
    "chargingWizard.overnightParking" : "Could you park the car overnight in a spot equipped with a charging station?",
    "chargingWizard.powerOutlet" : "Is there a regular power outlet close to where you park your car?",
    "chargingWizard.chargingAvailability" : " charging availability",
    "chargingWizard.noChargingAvailability" : "no charging availability",
    "chargingWizard.youWouldHave" : "you would have {resultText} ",
    "chargingWizard.useThisValue" : "Use this value",

    "evfilter" : "Filter",
    "evfilter.fuel" : "Fuel",
    "evfilter.fuelTooltip" : "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
    "evfilter.type" : "Type",

    "yes" : "Yes",
    "no" : "No",

    "evCard.electricRange" : "Electric Range",
    "evCard.totalRange" : "Total Range",
    "evCard.matchScore" : "MATCH SCORE",
    "evCard.seeDetails" : "See Details",
    "evCard.seeElectricVehicle" : "SEE ELECTRIC VEHICLE",

    "evSort.title" : "Sort By",
    "evSort.matchScore" : "Match Score",
    "evSort.electricRange" : "Electric Range",
    "evSort.priceLtH" : "Price: Low to High",
    "evSort.priceHtL" : "Price: High to Low",
    "evSort.alphabetical" : "Alphabetical",

    "pricePanels.cash" : "Cash",
    "pricePanels.loan" : "Loan",
    "pricePanels.lease" : "Lease",
    "pricePanels.loanPayment" : "Loan Payment",
    "pricePanels.downPayment" : "Down Payment",
    "pricePanels.leasePayment" : "Lease Payment",
    "pricePanels.perMonth" : " / month",
    "pricePanels.downPaymentDescription" : "10% of MSRP plus tax",
    "pricePanels.firstLeasePayment" : "FIRST LEASE PAYMENT",
    "pricePanels.incentivesForLease" : "Incentives For Lease",
    "pricePanels.seeAllIncentives" : "See All Incentives",
    "pricePanels.seeAllCosts" : "See All Costs",

    "ev.jumbotron.viewAllCars" : "View All Cars",
    "ev.carDetails.fastChargingTooltip" : "DC Fast Charger Speed:",
    "ev.carDetails.levelTwoChargerSpeedTooltip" : "Level 2 Charger Speed:",
    "ev.carDetails.batterySizeTooltip" : "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.",
    "ev.carDetails.treesPlanted" : "Trees Planted",
    "ev.carDetails.gasolineSaved" : "Gasoline Saved",
    "ev.carDetails.compareText" : "We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price.",
    "ev.cardetails.calc.dcRate" : "DC Fast Charging (DCFC) Rate",
    "ev.cardetails.calc.EPA" : "NRC Efficiency Rating",
    "ev.cardetails.calc.chargingRate" : "Charging Rate",
    "ev.cardetails.calc.vehicleBatteryCapacity" : "Vehicle Battery Capacity",
    "ev.cardetails.calc.maxACIntake" : "Vehicle Max AC Intake",
    "ev.cardetails.calc.lvlTwoChargingRate" : "Level 2 Charging Rate",
    "ev.cardetails.calc.minOflvl2AC" : "Minimum of Level 2 Charging Rate and Vehicle Max AC Intake",
    "ev.cardetails.calc.lvl2FullCharge" : "Time to Full Charge for Level 2",
    "ev.cardetails.calc.kmDrivenPerYr" : "Kilometers driven per year",
    "ev.cardetails.calc.milesDrivenPerYr" : "Miles driven per year",
    "ev.cardetails.calc.userInput" : "User Input",
    "ev.cardetails.calc.lPerKm":"Liters Per 100 Kilometers",
    "ev.cardetails.calc.milesPerGallon" : "Miles Per Gallon (MPG)",
    "ev.cardetails.calc.mpg" : " miles/gal",
    "ev.cardetails.calc.portionElectric" : "Portion of Electric Driving",
    "ev.cardetails.calc.portionElectricSource" : "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
    "ev.cardetails.calc.litersUsed":"Litres utilisés",
    "ev.cardetails.calc.litersUsedFormula" : "Kilometers driven * (1 - Portion of Electric Driving) * L/100 km",
    "ev.cardetails.calc.gallonsUsed" : "Gallons Used",
    "ev.cardetails.calc.gallonsUsedFormula" : "Miles driven * (1 - Portion of Electric Driving) / MPG",
    "ev.cardetails.calc.gallonsSaved" : "Gallons Saved",
    "ev.cardetails.calc.litersSaved":"Liters Saved",
    "ev.cardetails.calc.litersSavedFormula":"Liters used by Equivalent Gas Vehicle - Liters used by EV",
    "ev.cardetails.calc.gallonsSavedFormula" : "Gallons used by Equivalent Gas Vehicle - Gallons used by EV",
    "ev.cardetails.calc.electricEmissions" : "Electric Emissions in Lbs",
    "ev.cardetails.calc.electricEmissionsMetric" : "Electric Emissions in kg",
    "ev.cardetails.calc.electricEmissionsLbsMwh" : "Electric Emissions in Lbs/MWh",
    "ev.cardetails.calc.electricEmissionsLbsMwhMetric":"Electric Emissions in Kg/MWh",
    "ev.cardetails.calc.CFCG" : "Canada Fuel Consumption Guide",
    "ev.cardetails.calc.EIA" : "U.S. Energy Information Administration",
    "ev.cardetails.calc.gasolineEmissionsYrMetric":"Gasoline Emissions in kg of CO2/yr",
    "ev.cardetails.calc.gasolineEmissionsMetric" : "Gasoline Emissions in Kg per Liter",
    "ev.cardetails.calc.gasolineEmissionsYr" : "Gasoline Emissions in lbs of CO2/yr",
    "ev.cardetails.calc.gasolineEmissions" : "Gasoline Emissions in Lbs per Gallon",
    "ev.cardetails.calc.emissionsReudctions" : "Emissions Reductions",
    "ev.cardetails.calc.lbsCo2PerYr" : " lbs of CO2 per year",
    "ev.cardetails.calc.gasolineEmissionskg":"Gasoline Emissions in Kg",

    "ev.cardetails.calc.gasolineEmissionsLbs" : "Gasoline Emissions in Lbs",
    "ev.cardetails.calc.arborDayFoundation" : "Arbor Day Founadtion",
    "ev.cardetails.calc.emissionsPerTree" : "Emissions Saved per Tree",
    "ev.cardetails.calc.electricityEmissionsMetric":"Electricity Emissions in CO2 kg/MWh",
    "ev.cardetails.calc.electricityEmissions" : "Electricity Emissions in CO2 lbs/MWh",
    "ev.usedCarTitle" : "Used {car}",
    "ev.cardetails.electricRange" : "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
    "ev.carDetails.usedBatterySizeTooltip" : "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

    "ev.purchaseMethod" : "Purchase Method",
    "ev.purchaseMethodToolTip" : "In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off.",
    "ev.cash" : "Cash",
    "ev.loan" : "Loan",
    "ev.lease" : "Lease",
    "ev.milesDrivenAnnually" : "Miles Driven Per Year",
    "ev.kilometersDrivenAnnually" : "Kilometers Driven Per Year",
    "ev.electricPortion" : "Portion Electric for PHEV",
    "ev.electricPortionTooltip" : "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
    "ev.yearsOwnership" : "Years of Ownership/Lease",
    "ev.gasolinePrice" : "Gasoline Price ($/L)",
    "ev.interestRate" : "Interest Rate",
    "ev.electricityRateCalc" : "Some Canadian jurisdictions are currently offering COVID relief to lower electricity rates. The rates and pricing published on this website are based on publicly available sources and tailored to your postal code. Please consult your local electricity provider to confirm current pricing in your area.",

    "assumption.salesTaxPercent" : "Sales Tax %",
    "assumption.salesTax" : "Sales Tax",
    "assumption.gasolinePrice" : "Price of Gasoline",
    "assumption.downPayment" : "Down Payment",
    "assumption.electricityRate" : "Electricity Rate",
    "assumption.downPaymentValue" : "10% of MSRP + Tax",

    "ev.compareGasVehicle" : "Compare the {car} to a similar gas vehicle, the {gasCar}",
    "ev.compareOtherVehicles" : "COMPARE OTHER VEHICLES",
    "ev.incentives.title" : "Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}",
    "ev.reviews" : "Reviews from around the web",

    "chargingMap.updateZipcode" : "Update Post Code",
    "chargingMap.errorZipcode" : "Error: {workingZipcode} is not a valid postcode",
    "chargingMap.showFullListOfStations" : "FULL LIST OF STATIONS",
    "chargingMap.public" : "Public stations",
    "chargingMap.businessGovernment" : "Installed by business or government",
    "chargingMap.highPowerStations" : "High Power Stations",
    "chargingMap.DCChargers" : "DC fast charge or superchargers",
    "chargingMap.otherStations" : "Other Types of Stations",
    "chargingMap.privateStations" : "Private stations",
    "chargingMap.searchDealers" : "Search Qualified Dealers",
    "chargingMap.disclaimer" : "This map shows Level 2 (blue) and Level 3 (green) public charging stations across Canada. For more information on the different levels, or speeds, of charging, visit: {website}",
    "chargingMap.dataDisclaimer" : "Data courtesy of the Alternative Fuel Data Center. Map may not reflect latest availability of charging stations.",
    'chargingMap.chargerMapCopy': 'Data from the Alternative Fuel Data Center may not reflect latest availability. Submit suspected missing charging stations {link}. Please confirm availability of charging stations before commencing your journey.',
    'chargingMap.routeMapCopy': 'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model and automobile conditions.',

    "dealers.brands" : "Brands",
    "dealers.allBrands" : "All Brands",
    "dealers.allDealers" : "All Dealers",
    "dealers.contact" : "CONTACT",
    "dealers.website" : "WEBSITE",
    "dealers.findDealers" : "Find Dealers",

    "incentives.title" : "Electric Vehicle Incentives",
    "incentives.subTitle" : "You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live.",
    "incentives.single" : "Single",
    "incentives.married" : "Married",
    "incentives.headOfHousehold" : "Head of Household",
    "incentives.vehicleType" : "Vehicle Type",
    "incentives.vehicleTypeTooltip" : "Certain incentives depend on which car you purchase.",
    "incentives.tradeIn" : "Trade-In",
    "incentives.income" : "Income and tax status",
    "incentives.incomeTooltip" : "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.",
    "incentives.clunkerNone" : "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker.",
    "incentives.clunkertoolTip" : "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the ",
    "incentives.clunkertoolTipContd" : " are available in your region. Eligibility requirements vary by incentive; please check program details.",
    "incentives.householdSize" : "Household Size",
    "incentives.householdIncome" : "Household Income",
    "incentives.planClunker" : "Are you planning to turn in a clunker?",
    "incentives.location" : "Location",
    "incentives.locationTooltip" : "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.",
    "incentives.taxFilingStatus" : "Tax Filing Status",
    "incentives.whichVehicle" : "Which vehicle do you plan to purchase?",
    "incentives.incentiveEligibility" : "Update Incentive Eligibility",

    "homeChargers.subText" : "Select the vehicle you are interested in to see the specific charging time to a full battery.",
    "homeChargers.wallMounted" : "Wall Mounted",
    "homeChargers.portable" : "Portable",
    "homeChargers.price" : "Price",
    "homeChargers.cordLength" : "Cord Length",
    "homeChargers.wifi" : "WiFi Connectivity",
    "homeChargers.socket" : "Socket",
    "homeChargers.socketTooltip" : "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician.",
    "homeChargers.cordLengthLtH" : "Cord Length: Low to High",
    "homeChargers.cordLengthHtL" : "Cord Length: High to Low",
    "homeChargersCard.buy" : "Details & Buy",
    "homeChargersCard.beforeIncentives" : "Before Incentives",
    "homeChargersCard.socket" : "{socket} Socket",
    "homeChargersCard.teslaWarning" : "This charger only works with Teslas",
    
    "homeChargers.cable" : "{length} ft. Cable",
    "homeChargers.hardwired" : "Hardwired",
    "homeChargers.toFullCharge" : "to full charge",

    "electricians.residential" : "Residential",
    "electricians.commercial" : "Commercial",
    "electricians.industrial" : "Industrial",
    "electricians.type" : "Electrician Type",
    "electricians.searchElectricians" : "Search Qualified Electricians",

    "tabbed-maps-dealer-catalog-tab" : "Dealers",
    "tabbed-maps-all-stations-tab" : "Charging Stations",
    "tabbed-maps-electricians-tab" : "Electricians",
    "tabbed-maps-route-tab" : "Map a Route",

    "ev.showSources.electricityRate" : "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",
    "ev.showSources.portionElectricSource" : "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
    "ev.showSources.resaleValue" : "Resale Value",
    "ev.showSources.resaleValueSource" : "Function of vehicle MSRP and lifetime miles of {lifetimeMiles}, assuming negative exponential depreciation with distance where resale value is 60% after 36,000 miles",
    "ev.showSources.totalVehicleCost" : "Total Vehicle Cost",
    "ev.showSources.cashFormula" : "Vehicle MSRP * (1 + Sales Tax) - Incentives - Resale Value",
    "ev.showSources.assumption" : "Assumption:",
    "ev.showSources.loanAmount" : "Loan Amount",
    "ev.showSources.loanFormula" : "MSRP * (1 + Sales Tax) - Down Payment",
    "ev.showSources.monthlyPayments" : "Monthly Payments",
    "ev.showSources.monthlyPaymentsSource" : "Financial function of Loan Amount, Interest Rate, and Months of Ownership",
    "ev.showSources.monthsOfOwnership" : "Months of Ownership",
    "ev.showSources.totalMonthlyPayments" : "Total Monthly Payments",
    "ev.showSources.totalMonthlyPaymentsSource" : "Monthly Payment * Months of Ownership",
    "ev.showSources.totalLoanFormula" : "Down Payment + Total Monthly Payments - Incentives - Resale Value",
    "ev.showSources.capitalizedIncentives" : "Capitalized Incentives",
    "ev.showSources.capitalizedIncentivesSource" : "Incentives capitalized within the lease",
    "ev.showSources.capitalizedCost" : "Capitalized Cost",
    "ev.showSources.capitalizedCostSource" : "MSRP - Down Payment - Capitalized Incentives",
    "ev.showSources.monthlyDepreciationCost" : "Monthly Depreciation Cost",
    "ev.showSources.monthlyDepreciationCostSource" : "(Capitalized Cost - Resalve Value) / Months of Ownership",
    "ev.showSources.monthlyFinancingCost" : "Monthly Financing Cost",
    "ev.showSources.monthlyFinancingCostSource" : "(Capitalized Cost + Resale Value) * Money Factor",
    "ev.showSources.moneyFactor" : "Money Factor",
    "ev.showSources.moneyFactorSource" : "Equivalent to user input of {interestRateAsBasisPoints}",
    "ev.showSources.monthlyLeasePayment" : "Monthly Lease Payment",
    "ev.showSources.monthlyLeasePaymentSource" : "(Monthly Depreciation Cost + Monthly Financing Cost) * (1 + Sales Tax)",
    "ev.showSources.firstLeasePayment" : "First Lease Payment",
    "ev.showSources.firstLeasePaymentSource" : "Down Payment + Monthly Lease Payment",
    "ev.showSources.nonCapLeaseIncentives" : "Non-capitalized Lease Incentives",
    "ev.showSources.nonCapLeaseIncentivesSource" : "Incentives not captured in capitalized lease cost",
    "ev.showSources.leaseForumla" : "First Lease Payment + (Months Of Ownership - 1) * (Monthly Lease Payment) - Non-capitalized Lease Incentives",
    "ev.showSources.vehicleKWh" : "Vehicle's kWh per 100 mi",
    "ev.showSources.EPAEfficiency" : "EPA Efficiency Rating",
    "ev.showSources.dollarPerMileDriven" : "$/100 mi driven",
    "ev.showSources.dollarPerMileDrivenSource" : "Electricity Cost * Vehicle's kWh per 100 mi",
    "ev.showSources.lifetimeMiles" : "Lifetime Miles",
    "ev.showSources.totalElectricityCost" : "Total Electricity Cost",
    "ev.showSources.totalElectricityCostSource" : "$/100 mi driven * Lifetime Miles * {portionEVDriving}/ 100",
    "ev.showSources.gasolineCost" : "Gasoline Cost",
    "ev.showSources.vehicleMPG" : "Vehicle's MPG (miles per gallon)",
    "ev.showSources.totalGasolineCost" : "Total Gasoline Cost",
    "ev.showSources.totalGasolineCostSource" : "Gasoline Cost * Lifetime Miles *  {portionEVDriving}/ MPG",
    "ev.showSources.maintenancePerMile" : "Maintenance per mile for gasoline",
    "ev.showSources.maintenancePerMileSource" : "Extrapolation of average maintenance cost of {avgMaintenanceCost}, driven by vehicle MSRP in relation with average MSRP of {averageMSRP}",
    "ev.showSources.maintenanceCostReduction" :"EV Maintenance Cost Reduction",
    "ev.showSources.maintenanceCostReductionSource" : "{website}",
    "ev.showSources.maintenancePerMileElectricity" : "Maintenance per mile for electricity",
    "ev.showSources.maintenancePerMileElectricitySource" : "Maintenance per mile for gasoline * EV Maintenance Reduction Factor",
    "ev.showSources.blendedMaintenanceCostPerMile" : "Blended maintenance cost per mile",
    "ev.showSources.blendedMaintenanceCostPerMileSource" : "Portion of Electric Driving applied to Maintenance per mile for gasoline and for electricity",
    "ev.showSources.totalMaintenanceCost" : "Total Maintenance Cost",
    "ev.showSources.totalMaintenanceCostSource" : "Maintenance cost per mile * Lifetime Miles",
    "ev.showSources.averageInsurancePerYear" : "Average Insurance per Year",
    "ev.showSources.insurancePerYear" : "Insurance per Year",
    "ev.showSources.insurancePerYearSource" : "Extrapolation of average insurance cost in {state}, driven by vehicle MSRP in relation with average MSRP of {msrp}",
    "ev.showSources.yearsOfOwnership" : "Years of Ownership",
    "ev.showSources.totalInsuranceCost" : "Total Insurance Cost",
    "ev.showSources.totalInsuranceCostSource" : "Insurance cost per year * Years of Ownership",
    "here" : "here",
    "sources": "Sources",

    "learnMore" :"Learn More",
    "ev.cardetails.calc.nationalAverageElectricityEmissions" : "National Average Electricity Emissions",
    "ev.cardetails.calc.kgCo2PerYr" : "kg of CO2 per year",
    "ev.cardetails.calc.emissionPerTreePerYear" : "22 kg of CO2 per tree per year",
    "n/a" : "N/A",

    "title.home" : "PlugNDrive Buyer's Guide",
    "title.vehicles" : "Electric Vehicles | PlugNDrive Buyer's Guide",
    "title.compare" : "Compare Vehicles | PlugNDrive Buyer's Guide",
    "title.incentives" : "Incentives | PlugNDrive Buyer's Guide",
    "title.chargingStations" : "Charging Stations | PlugNDrive Buyer's Guide"

}
