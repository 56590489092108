const InsuranceCost = {
  perYear: function (vehicle, insuranceData, monthsOfOwnership) {
    if (!vehicle) return 0;
       let insuranceCost = 0;
       if (insuranceData) {
              for(const item of insuranceData) {
                     if(item.year_reference <= monthsOfOwnership / 12) {
                             insuranceCost += item.insurance_premium;
                     } else {
                            break;
                     }
              }
       }

       if(vehicle && !vehicle.equivalent_gas_vehicle) {
              insuranceCost = insuranceCost * 0.75;
       }

       return insuranceCost;
  },

  perMile: function (vehicle, milesDrivenAnnually, monthsOfOwnership, insuranceData) {
    if (!vehicle) return 0;
       let cost = this.perYear(vehicle, insuranceData, monthsOfOwnership);
       let yearsOfOwnership = monthsOfOwnership / 12;
       let costPerMile = (cost / milesDrivenAnnually) / yearsOfOwnership;
    return isFinite(costPerMile) ? costPerMile : 0;
  },
};

export default InsuranceCost;
