/**
 * Gets time to full charge of vehicle depending on vehicle battery capacity 
 * and charger amperage, after checking that we shouldn't return an error message 
 * instead.
 * 
 * @param {*} vehicle   Data object representing vehicle, as retrieved from the API.
 * @param {*} charger   Data object representing charger, as retrieved from the API.
 * 
 * @returns {*} A time in number of hours (float) or "NA" (string) if not able to calculate
 */
const getTimeToFullCharge = (vehicle, charger) => {
    return doCalcOrReportError(vehicle, charger); 
}

const doCalcOrReportError = (vehicle, charger) => {

    const doTheCalc = () => { return calculateTimeToFullCharge(vehicle, charger) };

    // @TODO if we get to where there are too many of these rules,
    // move to another data module
    const calcOrErrorFunctions = {
        'tesla' : () => {
            return vehicle.make.toLowerCase() === 'tesla'
                ? doTheCalc()
                : 'evs.chargercard.chargingtime.na'
        }
    }

    const foundCalcFunction = calcOrErrorFunctions[charger.make.toLowerCase()];

    return typeof foundCalcFunction === 'function'
        ? foundCalcFunction()
        : doTheCalc()
}

const calculateTimeToFullCharge = (vehicle, charger) => {
    const vehicleChargingPower = vehicle.dc_charging_power ? vehicle.dc_charging_power : vehicle.ac_charging_power;

    const chargingSpeed = Math.min(vehicleChargingPower, charger.amps * 240/1000);

    return vehicle.battery_capacity / chargingSpeed;
}

export default getTimeToFullCharge;