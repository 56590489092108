import React from "react";
import PropTypes from "prop-types";
import ZipcodeErrorState from "../../../../components/InputComponents/InputZipcode/ZipcodeErrorState";
import IconZip from "../../../assets/images/icons/icon-zip.png";
import {ValidatePostCode} from "../../../../utils/Helpers/CAPostCodeValidator";
import {FormattedMessage} from 'react-intl';


const ControlledInputZipcode = ({
  zipcode,
  setZipcode,
  handleSubmit,
  workingZipcode,
  id,
  isUpdating,
  isNotFound,
  showButtonBelow,
  showButtonInline,
  hideValidateRequest
}) => {
  const zipcodeIsValid = ValidatePostCode(workingZipcode);
  const zipcodeIsSynced = workingZipcode === zipcode;
  const isInvalid =
    ! isUpdating && (!zipcodeIsValid || (zipcodeIsSynced && isNotFound));
  const idAria = id + " help";
  const showButton = showButtonBelow || showButtonInline;

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  
  const inputClassName = isUpdating
    ? "form-control"
    : `form-control${isInvalid ? " is-invalid" : ""}${
        zipcodeIsSynced && !isNotFound ? " is-valid" : ""
      }`;

  const renderButton = (
    <button
      type="button"
      className="btn btn-default mb-2"
      onClick={setZipcode}
      disabled={isUpdating}
      id="update-zipcode-button"
    >
      Update Zipcode
    </button>
  );

  const renderError = (
    <ZipcodeErrorState
      zipcode={zipcode}
      workingZipcode={workingZipcode}
      isValid={zipcodeIsValid}
      isSynced={zipcodeIsSynced}
      isUpdating={isUpdating}
      isNotFound={isNotFound}
      hideValidateRequest={hideValidateRequest}
    />
  );

  return (
    <>
      <div className={showButtonInline ? "form-inline" : ""}>
        {!showButtonInline && <label htmlFor={id}>
          <FormattedMessage 
            id="zipcode"
            defaultMessage="Zipcode"
            description="Zipcode"
          />
          </label>}
        <div
          className={`input-group mb-2${showButtonInline ? " mr-sm-2" : ""}`}
        >
          <div className="input-group-prepend">
            <div className="input-group-text">
              <img src={IconZip} alt="" style={{ width: 15 }} />
            </div>
          </div>
          <input
            id={id}
            className={inputClassName}
            aria-describedby={idAria}
            value={workingZipcode}
            disabled={isUpdating}
            onChange={setZipcode}
            onBlur={setZipcode}
            onKeyDown={e => handleKeyDown(e)}
          />
        </div>
        {(showButtonBelow || !showButtonInline) && renderError}
        {showButton && renderButton}
      </div>
      {showButtonInline && renderError}
    </>
  );
};

export default ControlledInputZipcode;

ControlledInputZipcode.propTypes = {
  zipcode: PropTypes.string,
  setZipcode: PropTypes.func,
  handleSubmit: PropTypes.func,
  workingZipcode: PropTypes.string,
  id: PropTypes.string,
  isUpdating: PropTypes.bool,
  isNotFound: PropTypes.bool,
  showButtonBelow: PropTypes.bool,
  showButtonInline: PropTypes.bool,
  hideValidateRequest: PropTypes.bool
};