import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton";
import { FormatAsDollars, FormatCarName } from "../../../utils/Helpers/Format";
import calcTotalCostOfOwnership from "../../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
import { VehicleCost } from "../../../functions/vehicle/CostOfOwnership/calcs";

import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import times from "lodash/times";

import { FormattedMessage, useIntl } from "react-intl";

// import "./BreakevenChart.css";

const breakevenCosts = (car, get, yearsOfOwnership, salesTax, insuranceData) => {
  return yearsOfOwnership.map((year) => {
    switch (year) {
      case 0:
        return parseInt(VehicleCost.afterIncentives(car, salesTax));

      case yearsOfOwnership.length - 1:
        return calcTotalCostOfOwnership(
          car,
          "cash",
          year * 12,
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          get("includeResaleValue"),
          get("salesTax").value,
          get("electricityCosts"),
          get("userLocation"),
          insuranceData
        ).summed.total;

      default:
        return calcTotalCostOfOwnership(
          car,
          "cash",
          year * 12,
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          false, // do not include resale value in intermediate years,
          get("salesTax").value,
          get("electricityCosts"),
          get("userLocation"),
          insuranceData
        ).summed.total;
    }
  });
};

const BreakevenChart = ({
  cars,
  forceUserPrefsPresets,
  onAnimationComplete,
  insuranceData
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const yearsOfOwnership = times(get("monthsOfOwnership") / 12 + 1, (i) => i);

  const salesTax = userPrefs.get("salesTax");

  const carCosts = cars.map((car) => {
    return breakevenCosts(car, get, yearsOfOwnership, salesTax.value, insuranceData);
  });

  let subTitle = (
    <p className="h3 my-3 graph-sub-title">
      <FormattedMessage
        id="graph.costToBreakdown.subTitle"
        defaultMessage="Compare the cumulative lifetime cost of the {car1} to a {car2}"
        description="Cost to Breakdown Subtitle"
        values={{
          car1: FormatCarName(cars[0]),
          car2: FormatCarName(cars[1]),
        }}
      />
    </p>
  );

  let datasets = [
    {
      data: carCosts[1],
      label: FormatCarName(cars[1]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_CAR_1}`,
      backgroundColor: "transparent",
      lineTension: 0,
    },
    {
      data: carCosts[0],
      label: FormatCarName(cars[0]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_CAR_2}`,
      backgroundColor: "transparent",
      lineTension: 0,
    },
  ];

  if (cars.length === 3) {
    subTitle = (
      <p className="h3 my-3 graph-sub-title">
        <FormattedMessage
          id="graph.costToBreakdown.subTitleThreeCars"
          defaultMessage="Compare the cumulative lifetime cost of {car1}, {car2} and {car3}"
          description="Cost to Breakdown Subtitle 3 Cars"
          values={{
            car1: FormatCarName(cars[0]),
            car2: FormatCarName(cars[1]),
            car3: FormatCarName(cars[2]),
          }}
        />
      </p>
    );
    datasets.push({
      data: carCosts[2],
      label: FormatCarName(cars[2]),
      borderColor: `#0000ff`,
      backgroundColor: "transparent",
      lineTension: 0,
      borderDash: [10, 10],
    });
  }

  const title = (
    <>
      {/* <p className="h3 my-3">
      {intl.formatMessage({ id: "graph.costToBreakdown", defaultMessage: "Cost to Breakdown"})}
    </p> */}
      {subTitle}
    </>
  );

  const chartData = {
    labels: yearsOfOwnership,
    datasets: datasets,
  };

  const CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const value = FormatAsDollars(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
            intl.locale
          );
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          return " " + label + ": " + value;
        },
        title: function (tooltipItem) {
          return (
            intl.formatMessage({ id: "graph.year", defaultMessage: "Year" }) +
            " " +
            tooltipItem[0].xLabel
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            font: {
              weight: 700,
            },
            beginAtZero: false,
            callback: function (value) {
              return FormatAsDollars(value, intl.locale);
            },
          },
        },
      ],
    },
    legend: {
      position: "bottom",
      onClick: function (e) {
        e.stopPropagation();
      },
    },
    plugins: {
      datalabels: {
        // chartjs-plugin-datalabels is enabled on this project,
        // so without returning "" the raw values will be shown for each data point
        formatter: () => "",
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700,
        },
      },
    },
  };

  const chartOptions = Object.assign({}, CHART_JS_OPTIONS, {
    animation: {
      onComplete: onAnimationComplete,
    },
  });

  return (
    <div className="BreakevenChart input-well text-center">
      {title}
      <div className="chart-container d-none d-lg-block d-xl-block">
        <div>
          <Line
            data={chartData}
            type="line"
            options={chartOptions}
            height={250}
          />
        </div>
      </div>

      <ShowHideButton
        buttonText={intl.formatMessage({
          id: "graph.graphValues",
          defaultMessage: "Graph Values",
        })}
        displayChildrenOnlyOnTablet
      >
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    {intl.formatMessage({
                      id: "graph.year",
                      defaultMessage: "Year",
                    })}
                  </th>
                  <th scope="col">{FormatCarName(cars[0])}</th>
                  <th scope="col">{FormatCarName(cars[1])}</th>
                  {cars.length === 3 ? (
                    <th scope="col">{FormatCarName(cars[2])}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {yearsOfOwnership.map((year) => {
                  return (
                    <tr key={year}>
                      <th scope="row">
                        {intl.formatMessage({
                          id: "graph.year",
                          defaultMessage: "Year",
                        })}{" "}
                        {year}
                      </th>
                      <td>{FormatAsDollars(carCosts[0][year], intl.locale)}</td>
                      <td>{FormatAsDollars(carCosts[1][year], intl.locale)}</td>
                      {cars.length === 3 ? (
                        <td>
                          {FormatAsDollars(carCosts[2][year], intl.locale)}
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p className="text-left" style={{ color: "#666" }}>
              <i>
                {intl.formatMessage({
                  id: "graph.costToBreakdown.note",
                  defaultMessage:
                    "Note: Breakeven chart assumes the vehicles are purchased in cash.",
                })}
              </i>
            </p>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default BreakevenChart;

BreakevenChart.propTypes = {
  car: PropTypes.object,
  comparedCar: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  onAnimationComplete: PropTypes.func,
};
