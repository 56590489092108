import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import {
  ALL_STATIONS_TAB_ID,
  MAP_TABS,
  DEALER_CATALOG_TAB_ID,
  ELECTRICIANS_TAB_ID,
  ROUTE_MAP_TAB_ID
} from "../../constants/mapTabs";
import ChargingMap from '../LocationMap/ChargingMap';
import DealerMap from '../LocationMap/DealerMap';
import ElectriciansMap from '../LocationMap/ElectriciansMap';
import TravelRouteMap from '../LocationMap/TravelRouteMap/TravelRouteMap';

import "./TabbedMaps.scss";

const activeClass = (tabNumber, activeTab) =>
  activeTab === tabNumber ? "active" : "";

const enabledTabs = MAP_TABS.filter(tab => tab.isEnabled);

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  userLocation,
  dealerLocations,
  zipcode
}) => (
  <div className="TabbedMaps">
    <div className="form-group d-md-none">
      <select
        value={activeTabId}
        onChange={e => toggle(e.target.value)}
        className="form-control"
      >
        {enabledTabs.map(tab => (
          <option key={tab.name} value={tab.id}>
            {tab.name}
          </option>
        ))}
      </select>
    </div>
    <div className="tabbed-maps-panel">
      <Nav tabs className="d-none d-md-flex">
        {enabledTabs.map(tab => (
          <NavItem key={tab.name}>
            <NavLink
              className={activeClass(tab.id, activeTabId)}
              onClick={() => {
                toggle(tab.id);
              }}
            >
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTabId}>
        {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
          <TabPane tabId={ALL_STATIONS_TAB_ID}>
            <ChargingMap
              userLocation={userLocation}
              isVisible={activeTabId === ALL_STATIONS_TAB_ID}
            />
          </TabPane>
        )}
        {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
          <TabPane tabId={ROUTE_MAP_TAB_ID}>
            <TravelRouteMap isVisible={activeTabId === ROUTE_MAP_TAB_ID} />
          </TabPane>
        )}
        {process.env.REACT_APP_PAGES_DEALERS_ENABLED && (
          <TabPane tabId={DEALER_CATALOG_TAB_ID}>
            <DealerMap
              userLocation={userLocation}
              dealerLocations={dealerLocations}
              isVisible={activeTabId === DEALER_CATALOG_TAB_ID}
            />
          </TabPane>
        )}
        {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && (
          <TabPane tabId={ELECTRICIANS_TAB_ID}>
            <ElectriciansMap
              userLocation={userLocation}
              zipcode={zipcode}
              isVisible={activeTabId === ELECTRICIANS_TAB_ID}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  </div>
);

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool
};

export default TabbedMaps;
