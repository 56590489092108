import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
// import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"
import { FormattedMessage } from 'react-intl';
import twitterLogo from "../../../assets/images/icons/twitter-logo.svg";
import facebookLogo from "../../../assets/images/icons/facebook-logo.svg";
import linkedinLogo from "../../../assets/images/icons/linkedin-logo.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = ({ changeLanguage }) => {

  // const languageOptions = () => {
  //   return (
  //     <>
  //       <option
  //         value={'ES'}
  //         key={'ES'}
  //       >
  //         {'Spanish'}
  //       </option>
  //     </>
  //   )
  // }

  // const onChangeLanguage = e => {
  //   changeLanguage(e.target.value)
  //   SmoothScroll("root")
  // }

  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="social-links">
              <li>
                <a href="https://twitter.com/plugn_drive">
                  <img src={twitterLogo} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/PlugNDrive">
                  <img src={facebookLogo} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://ca.linkedin.com/company/plug'n-drive">
                  <img src={linkedinLogo} alt="LinkedIn" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <ul className="footer-links">
              <li>
                {process.env.REACT_APP_PAGES_HOME_ENABLED ? (<>
                  <Link to="/">
                    <FormattedMessage
                      id="home"
                      defaultMessage="Home"
                      description="Home Header"
                    />

                  </Link>
                  <br />
                </>) : (null)}
              </li>
              <li>
                {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
                  <Link to="/vehicles">
                    <FormattedMessage
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Footer"
                      values={{
                        electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                      }}
                    />
                  </Link>
                  <br />
                </>) : (null)}
              </li>
              <li>
                {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
                  <Link to="/compare-vehicles">
                    <FormattedMessage
                      id="compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Footer"
                      values={{
                        compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE
                      }}
                    />
                  </Link>
                  <br />
                </>) : (null)}
              </li>
              <li>
                {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
                  <Link to="/incentives">
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="Incentives"
                      description="Incentives Footer"
                      values={{
                        incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                      }}
                    />
                  </Link>
                  <br />
                </>) : (null)}
              </li>
              <li>
                {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
                  <Link to="/charging-stations">
                    <FormattedMessage
                      id="chargingStations"
                      defaultMessage="chargingStations"
                      description="chargingStations Header"
                    />
                  </Link>
                  <br />
                </>) : (null)}
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <p className="copyright" style={{ marginBottom: 0, color: "#fff", fontSize: "11px" }}>
              <span style={{ fontFamily: "Arial, Helvetica",marginLeft:"-300px" }}>&copy;</span>
              {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}.&nbsp;
              <FormattedMessage
                id="footer.allRights"
                defaultMessage="All Rights Reserved."
                description="All Rights Reserved."
              />
           
            <div className="disclaimer_container" style={{marginTop:"-19px"}}>
              <DisclaimerComponent image="zappynobackground" 
                            clientName="Plug 'N Drive"
                            wrapperStyle={{padding:"0px"}} 
                            imageStyle={{marginLeft:"375px"}}/>
            </div>
            </p>
            {/* <p style={{ textAlign: "center", color: "#fff", fontSize: "11px" }}>
              <span style={{ fontFamily: "Arial, Helvetica" }}>
                <FormattedMessage
                id="footer.poweredByJDP"
                defaultMessage="Powered by J.D. Power ZappyRide"
                description="Powered by J.D. Power ZappyRide"
              />
                <a style={{ color: "#fff", fontSize: "11px" }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices"> Legal notices.</a>
              </span>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
