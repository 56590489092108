import React, { useState, useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import PropTypes from "prop-types";
import "./PricePanels.scss";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FormatAsDollars } from "./../../../utils/Helpers/Format";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";

import { FormattedMessage, useIntl } from 'react-intl';

const PricePanels = ({ paymentDetails }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const [activeTab, setTab] = useState("1");

  const updatePurchaseMethod = (purchaseMethodValue) => {
    userPrefs.set({ purchaseMethod: purchaseMethodValue });
    switch (purchaseMethodValue) {
      case "cash":
        setTab("1");
        break;
      case "loan":
        setTab("2");
        break;
      case "lease":
        setTab("3");
        break;
      default:
        setTab("1");
    }

  }

  const scrollToCostsButton = (
    <span
      style = {{color: "#23BEC6", fontWeight: 600, cursor: "pointer"}}
      onClick={e => SmoothScroll("CostOfOwnership")}
    >
      {intl.formatMessage({ id: "pricePanels.seeAllCosts", defaultMessage: "See All Costs"})} &#62;
    </span>
  );
  
  const scrollToIncentivesButton = (
    <span
      style = {{color: "#23BEC6", fontWeight: 600, cursor: "pointer"}}
      onClick={e => SmoothScroll("EVIncentives")}
    >
      {intl.formatMessage({ id: "pricePanels.seeAllIncentives", defaultMessage: "See All Incentives"})} &#62;
    </span>
  );



  if (!paymentDetails) return null;

  return (
    <>
      <div className="PricePanels">
        <Nav pills className="nav-fill">
          <NavItem>
            <NavLink
              className={(userPrefs.get("purchaseMethod") === "cash") ? "active" : ""}
              onClick={() => updatePurchaseMethod("cash")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "cash" })}
              tabIndex="0"
            >
                <span>
                <FormattedMessage
                  id="pricePanels.cash"
                  defaultMessage="Cash"
                  description="Price Panel Cash"
                />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={userPrefs.get("purchaseMethod") === "loan" ? "active" : ""}
              onClick={() => updatePurchaseMethod("loan")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "loan" })}
              tabIndex="0"
            >
              <span>
                <FormattedMessage
                  id="pricePanels.loan"
                  defaultMessage="Loan"
                  description="Price Panel Loan"
                />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={userPrefs.get("purchaseMethod") === "lease" ? "active" : ""}
              onClick={() => updatePurchaseMethod("lease")}
              onKeyPress={() => userPrefs.set({ purchaseMethod: "lease" })}
              tabIndex="0"
            >
              <span>
                <FormattedMessage
                  id="pricePanels.lease"
                  defaultMessage="Lease"
                  description="Price Panel Lease"
                />
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.afterIncentives, intl.locale)}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="vehicle.msrp"
                          defaultMessage="MSRP"
                          description="Price Panel MSRP"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(paymentDetails.msrp, intl.locale)}
                      </p>
                      {scrollToCostsButton}
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="vehicle.estimatedIncentives"
                          defaultMessage="Estimated Incentives"
                          description="Price Panel Estimated Incentives"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForPurchase, intl.locale
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="pricePanels.loanPayment"
                        defaultMessage="Loan Payment"
                        description="Price Panel Loan Payment"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.loanAmountPerMonth) +
                        intl.formatMessage({ id: "pricePanels.perMonth", defaultMessage: " / month"})}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="pricePanels.downPayment"
                          defaultMessage="Down Payment"
                          description="Price Panel Down Payment"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(paymentDetails.downPayment)}
                      </p>
                        <small>
                          <FormattedMessage
                            id="pricePanels.downPaymentDescription"
                            defaultMessage="10% of MSRP plus tax"
                            description="Price Panel Down Payment Description"
                          />
                        </small>
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="vehicle.estimatedIncentives"
                          defaultMessage="ESTIMATED INCENTIVES"
                          description="ESTIMATED INCENTIVES"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForPurchase
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle">
                      <FormattedMessage
                        id="pricePanels.leasePayment"
                        defaultMessage="Lease Payment"
                        description="Price Panel Lease Payment"
                      />
                    </p>
                    <p className="h1">
                      {FormatAsDollars(paymentDetails.leaseAmountPerMonth) +
                        intl.formatMessage({ id: "pricePanels.perMonth", defaultMessage: " / month"})}
                    </p>
                  </div>
                </div>
                <div className="PanelItem">
                  <div className="row">
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="pricePanels.firstLeasePayment"
                          defaultMessage="First Lease Payment"
                          description="Price Panel First Lease Payment"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(paymentDetails.leaseFirstPayment)}
                      </p>
                      {scrollToCostsButton}
                    </div>
                    <div className="col">
                      <p className="RenderItemTitle">
                        <FormattedMessage
                          id="pricePanels.incentivesForLease"
                          defaultMessage="Incentives For Lease"
                          description="Price Panel Incentives For Lease"
                        />
                      </p>
                      <p className="h2">
                        {FormatAsDollars(
                          paymentDetails.totalIncentivesForLease
                        )}
                      </p>
                      {scrollToIncentivesButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default PricePanels;

PricePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
