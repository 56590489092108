const MaintenanceCost = {
       maintenanceCostPerGasMile: function(vehicle, milesDriven, monthsOfOwnership, maintenanceData) {
              if (!maintenanceData) return 0;
              var yearsOfOwnership = monthsOfOwnership / 12;
              var totalMaintenanceCost = 0;
              for (const item of maintenanceData) {
                     if(item.year_reference <= yearsOfOwnership) {
                            totalMaintenanceCost += item.annual_cost;
                     } else {
                            break;
                     }
              }
              if(vehicle && !vehicle.equivalent_gas_vehicle) {
                     totalMaintenanceCost = totalMaintenanceCost * 1.429;
              }
              return totalMaintenanceCost / milesDriven;
       },

       maintenanceCostPerElectricMile: function(milesDriven, monthsOfOwnership, maintenanceData) {
              if (!maintenanceData) return 0;
              var yearsOfOwnership = monthsOfOwnership / 12;
              var maintenanceCostPerYear = 0;
              for (const item of maintenanceData) {
                     if(item.year_reference <= yearsOfOwnership) {
                            maintenanceCostPerYear += item.annual_cost;
                     } else {
                            break;
                     }
              }
              var totalMaintenanceCost = maintenanceCostPerYear;
              return totalMaintenanceCost / milesDriven;
       },

       perMile: function(vehicle, milesDriven, monthsOfOwnership, electricMilesPortionForPhev, maintenanceData) {
              if (!vehicle) return 0;

              let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";
              var yearsOfOwnership = monthsOfOwnership / 12;
              let cost = this.maintenanceCostPerGasMile(vehicle, milesDriven,  monthsOfOwnership, maintenanceData);

              if (["BEV"].includes(fuelType.toUpperCase()))
              cost = this.maintenanceCostPerElectricMile(milesDriven, monthsOfOwnership, maintenanceData);
              if (["PHEV"].includes(fuelType.toUpperCase())) {
              cost =
                     (electricMilesPortionForPhev / 100) *
                     this.maintenanceCostPerElectricMile(milesDriven, monthsOfOwnership, maintenanceData) +
                     (1 - electricMilesPortionForPhev / 100) *
                     this.maintenanceCostPerGasMile(milesDriven, monthsOfOwnership,maintenanceData);
       }

       cost = cost / yearsOfOwnership;

    return isFinite(cost) ? cost : 0;
  }
};

export default MaintenanceCost;
