import {
  EMISSIONS_PER_TREE_IN_KG_OF_CO2,
  ELECTRICITY_EMISSIONS_IN_KG_PER_MWH,
  GASOLINE_EMISSIONS_IN_KG_PER_LITER
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import isBEV from "../../../functions/vehicle/isBEV";
import isPHEV from "../../../functions/vehicle/isPHEV";

const calcEmissions = {
  electricEmissionsInLbs: function(kwhPer100Km, km) {
    return (
      ELECTRICITY_EMISSIONS_IN_KG_PER_MWH.value * (kwhPer100Km/1000/100) * km
    );
  },

  gasolineEmissionsInLbs: function(lKm, km) {
    return (GASOLINE_EMISSIONS_IN_KG_PER_LITER.value * km) * lKm/100;
  },

  inLbs: function(vehicle, milesDrivenAnnually, electricMilesPortionForPhev) {
    if (!vehicle) return 0;
    let emissions = 0;

    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      emissions = this.gasolineEmissionsInLbs(
        vehicle.fossil_fuel_efficiency,
        milesDrivenAnnually
      );
    }

    if (isBEV(vehicle)) {
      emissions = this.electricEmissionsInLbs(
        vehicle.electric_efficiency,
        milesDrivenAnnually
      );
    }

    if (isPHEV(vehicle)) {
      emissions =
        this.electricEmissionsInLbs(
          vehicle.electric_efficiency,
          milesDrivenAnnually * (electricMilesPortionForPhev / 100)
        ) +
        this.gasolineEmissionsInLbs(
          vehicle.fossil_fuel_efficiency,
          milesDrivenAnnually * (1 - electricMilesPortionForPhev / 100)
        );
    }
    return emissions;
  },

  reductionInLbs: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.inLbs(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) - this.inLbs(vehicle, milesDrivenAnnually, electricMilesPortionForPhev)
    );
  },
  treesPlanted: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.reductionInLbs(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) / EMISSIONS_PER_TREE_IN_KG_OF_CO2.value
    );
  },
  gallonsUsed: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    if (!vehicle) return 0;

    // All-electric --> no gasoline used
    if (isBEV(vehicle)) {
      return 0;
    }

    // Not electric --> all miles on gasoline
    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      return milesDrivenAnnually * vehicle.fossil_fuel_efficiency/100;
    }

    return (
      ((1 - electricMilesPortionForPhev / 100) * milesDrivenAnnually) *
      vehicle.fossil_fuel_efficiency / 100
    );
  },
  gallonsSaved: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.gallonsUsed(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) -
      this.gallonsUsed(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      )
    );
  }
};

export default calcEmissions;