import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

import logo from "../../../assets/images/logo.svg"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';
// import { MAP_TABS } from '../../../../constants/mapTabs';

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="https://plugndrive.ca/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "116px" }}
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="" navbar>
              { process.env.REACT_APP_PAGES_HOME_ENABLED  ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                        id="home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="browseElectricVehicles"
                        defaultMessage="Browse Electric Vehicles"
                        description="Browse Electric Vehicles"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              <NavItem>
                  <NavLink
                    title={page === "compare-vehicles" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              <NavItem>
                <NavLink
                  title={page === "map" ? "Active Page" : null}
                  to="/charging-stations"
                >
                  <span>
                    <FormattedMessage
                        id="chargingStations"
                        defaultMessage="chargingStations"
                        description="chargingStations Header"
                      />
                  </span>
                </NavLink>
              </NavItem>
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ):(null)}
            <div className="language-toggle">
              <button className={language === "EN" ? "btn-lang active" : "btn-lang"} onClick={() => changeLanguage('EN')}>English</button>
              <button className={language === "FR" ? "btn-lang active" : "btn-lang"} onClick={() => changeLanguage('FR')}>Français</button>
            </div>
            </Nav>
          </Collapse>
        </Navbar>

      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
