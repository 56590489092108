const FormatAsDollars = (val, locale) => {
  if (val && locale) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0,currencyDisplay: 'narrowSymbol' }).format(val)
  }
  return (
    "$" +
    Math.round(val)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
}

const FormatAsHundredsOfDollars = val => {
  return (
    "~$" +
    (Math.round(val / 100) * 100)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

const FormatAsCents = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(2)
      .toString()
  );
};

const FormatAsTwoDecimals = val => {
  return parseFloat(val)
    .toFixed(2)
    .toString();
};

const FormatAsCentsDetailed = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(4)
      .toString()
  );
};

const FormatAsPercent = val => {
  return parseFloat(val).toFixed(2) + "%";
};

const FormatAsPercentRounded = val => {
  return parseFloat(val).toFixed(0) + "%";
};

const FormatAsInt = val => {
  return parseFloat(val).toFixed(0);
};

const FormatAsThousands = (val, locale) => {
  return new Intl.NumberFormat(locale).format(val)
};

const FormatAsTime = time => {
  var hh = Math.floor(time);
  var mm = Math.round((time - hh) * 12) * 5;
  let renderHours = hh === 0 ? "" : hh + " hr";
  let renderMinutes = mm === 0 ? "" : " " + mm + " min";
  return renderHours + renderMinutes;
};

const FormatCarName = car => {
  let carTrim = car.trim ? car.trim  : "" ;
  return car
  ? (car.make + " " + car.model + " " + carTrim).toString().trim()
  : null;
};

const FormatCarModelAndTrim = car => {
  let carTrim = car.trim ? car.trim  : "" ;
  return car
  ? (car.model + " " + carTrim).toString().trim()
  : null;
};

const FormatInThousands = val => {
  return `$${Math.round(val / 1000)}k`
}

export {
  FormatAsDollars,
  FormatAsHundredsOfDollars,
  FormatAsCents,
  FormatAsTwoDecimals,
  FormatAsCentsDetailed,
  FormatAsPercent,
  FormatAsPercentRounded,
  FormatAsInt,
  FormatAsThousands,
  FormatAsTime,
  FormatCarName,
  FormatCarModelAndTrim,
  FormatInThousands
};
