import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";
import CanadaFlag from "../../client_customizations/assets/images/ca_flags/canada_flag.svg";
import AlbertaFlag from "../../client_customizations/assets/images/ca_flags/alberta_flag.svg";
import BritishColumbiaFlag from "../../client_customizations/assets/images/ca_flags/britishcolombia_flag.svg";
import ManitobaFlag from "../../client_customizations/assets/images/ca_flags/manitoba_flag.svg";
import NewBrunswickFlag from "../../client_customizations/assets/images/ca_flags/newbrunswick_flag.svg";
import NewfoundlandLabradorFlag from "../../client_customizations/assets/images/ca_flags/newfoundland_flag.svg";
import NovaScotiaFlag from "../../client_customizations/assets/images/ca_flags/novascotia_flag.svg";
import OntarioFlag from "../../client_customizations/assets/images/ca_flags/ontario_flag.svg";
import PrinceEdwardIslandFlag from "../../client_customizations/assets/images/ca_flags/princeedward_flag.svg";
import QuebecFlag from "../../client_customizations/assets/images/ca_flags/quebec_flag.svg";
import SaskatchewanFlag from "../../client_customizations/assets/images/ca_flags/saskatchewan_flag.svg";
import YukonFlag from "../../client_customizations/assets/images/ca_flags/yukon_flag.svg"

const IncentiveCard = props => {
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }
  
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

    let showFlag = incentive => {
      switch(incentive.grantor) {
        case "Canada" :
          return (
            <div className="IncentiveCardFlag">
              <img src={CanadaFlag} alt="Canada Flag"/>
            </div>
          )
        case "Alberta" :
          return (
            <div className="IncentiveCardFlag">
              <img src={AlbertaFlag} alt="Alberta Flag"/>
            </div>
          )
        case "British Columbia" :
          return (
            <div className="IncentiveCardFlag">
              <img src={BritishColumbiaFlag} alt="British Columbia Flag"/>
            </div>
          )
        case "Manitoba" :
          return (
            <div className="IncentiveCardFlag">
              <img src={ManitobaFlag} alt="Manitoba Flag"/>
            </div>
          )
        case "New Brunswick" :
          return (
            <div className="IncentiveCardFlag">
              <img src={NewBrunswickFlag} alt="New Brunswick Flag"/>
            </div>
          )
        case "Newfoundland and Labrador" :
          return (
            <div className="IncentiveCardFlag">
              <img src={NewfoundlandLabradorFlag} alt="Newfoundland and Labrador Flag"/>
            </div>
          )
        case "Nova Scotia" :
          return (
            <div className="IncentiveCardFlag">
              <img src={NovaScotiaFlag} alt="Nova Scotia Flag"/>
            </div>
          )
        case "Ontario" :
          return (
            <div className="IncentiveCardFlag">
              <img src={OntarioFlag} alt="Ontario Flag"/>
            </div>
          )
        case "Prince Edward Island" :
          return (
            <div className="IncentiveCardFlag">
              <img src={PrinceEdwardIslandFlag} alt="Prince Edward Island Flag"/>
            </div>
          )
        case "Quebec" :
          return (
            <div className="IncentiveCardFlag">
              <img src={QuebecFlag} alt="Quebec Flag"/>
            </div>
          )
        case "Saskatchewan" :
          return (
            <div className="IncentiveCardFlag">
              <img src={SaskatchewanFlag} alt="Saskatchewan Flag"/>
            </div>
          )
          case "Yukon" :
            return (
              <div className="IncentiveCardFlag">
                <img src={YukonFlag} alt="Yukon Flag"/>
              </div>
            )
        default :
          return (
            <div className="IncentiveCardFlag">
              <img src={CanadaFlag} alt="Canada Flag"/>
            </div>
          )
      }
    }

  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="IncentiveCardBody">
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="h6">{incentive.grantor}</p>
        <p className="h6">{incentive.name}</p>
      </div>
      <div className="IncentiveCardBottom">
        {description}
      </div>
      {showFlag(incentive)}
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
