import calculableMonetaryIncentives from "../../../constants/calculableMonetaryIncentives"

/**
 * Verifies that incentive type allows inclusion in calculations.
 * 
 * @param {*} incentives    Can be either a single incentive or an array of incentives.
 */
const verifyIncentiveType = (incentives, validIncentives = calculableMonetaryIncentives) => {
    if(!incentives) {
        return [];
    }

    const allIncentives = Array.isArray(incentives) 
        ? incentives
        : [incentives];

    const filteredIncentives = allIncentives.filter((incentive) => {
        return incentive.type 
            && validIncentives.has(incentive.type.toUpperCase());
    });

    return filteredIncentives;
}

export default verifyIncentiveType;