import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../assets/images/browse-electric-card.png";
import DISCOVER_INCENTIVES from "../../assets/images/discover-incentives-card.png";
import LOCATE_CHARGING_STATIONS from "../../assets/images/locate-charging-stations-card.png";
import FIND_DEALERS from "../../assets/images/icons/find-dealers.svg";
import HOME_CHARGERS from "../../assets/images/icons/home-chargers.svg";
import { Link } from "react-router-dom";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";
import { FormattedMessage } from "react-intl";

const LinkCard = ({ type }) => {
  let src, link, scrollTo, title;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      link = "/vehicles";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
            description="HomePage Linkcard EVs"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "DISCOVER_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="DISCOVER {lineBreak} INCENTIVES"
            description="HomePage Linkcard Incentives"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      link = "/charging-stations";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="Locate Charging {lineBreak} Stations"
            description="HomePage Linkcard Stations"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    case "FIND_DEALERS":
      src = FIND_DEALERS;
      link = "/dealers";
      title = (
        <span>
          Find <br />
          Dealers
        </span>
      );
      break;
    case "HOME_CHARGERS":
      src = HOME_CHARGERS;
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.homeChargers"
            defaultMessage="HOME {lineBreak} CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values={{
              lineBreak: <br />,
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card" onClick={(e) => SmoothScroll(scrollTo)}>
        <div>
          <img src={src} alt="" />
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <div>
          <img src={src} alt="" />
          <p className="h6">{title}</p>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string,
};
