// Regression Coefficients are calculated https://docs.google.com/spreadsheets/d/1d2dYl_JoBgdICGurjc2MaJylAU41hlk2yjbx0wuhygY/edit#gid=1241204193 

// Battery Degredation is Year: Percent Lost
const batteryDegredation = {0: 1, 1: .98, 2: .96, 3: .939, 4: .919, 5: .899, 6: .879}

const ageCalculator = modelYear => {
  return Math.max(0, new Date().getFullYear() - modelYear)
}

const UsedVehicle = (vehicle) => {
    vehicle.battery_capacity = Math.round(batteryDegredation[ageCalculator(vehicle.model_year)] * vehicle.battery_capacity)
    vehicle.electric_range = Math.round(batteryDegredation[ageCalculator(vehicle.model_year)] * vehicle.electric_range)

    return vehicle
};

export default UsedVehicle;
